@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"




































































































































.hits-rate
  margin-bottom: $size-m

  +mq-m--mf
    margin-bottom: 0

  &__body
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    padding: $size-xl
    height: 100%

  &__chart
    margin-bottom: $size-m

  &__text
    display: flex
    flex-flow: column
    align-items: center
    justify-content: center
    max-width: 100%

    &__count
      font-size: $font-size-heading-6
      color: $color-ink-light
