@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"
















































.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l

  +mq-s--mf
    +flex-center-start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      margin-bottom: $size-xs
