@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



































































































































































.assessment-report

  &__charts-section
    display: flex
    flex-flow: column
    padding: $size-m 0

    +mq-m--mf
      flex-flow: row
      justify-content: space-between

    &__hits-rate,
    &__placement-histogram
      width: 100%

      +mq-m--mf
        width: 48.5%
