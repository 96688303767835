@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






















.radial-progress-loader::after
  +pseudo
  position: absolute
  top: 10px
  left: 10px
  right: 10px
  bottom: 10px
  background: $color-white
  border-radius: 50%
