@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"










































































.question-drawer-hit-rate
  border-bottom: 1px solid $color-ink-lightest
  padding: $size-m $size-l
  display: flex
  flex-flow: column
  align-items: center
  justify-content: center

  +mq-m--mf
    flex-flow: row
    align-items: center
    justify-content: start

  &__donut
    margin-bottom: $size-s

    +mq-s--mf
      margin-right: $size-s
      margin-bottom: 0

  &__text
    &__title
      color: $color-ink-light
      font-size: 11px
      text-transform: uppercase
      letter-spacing: 1.3px
      margin-bottom: $size-xs
