@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"























































.header-toolbar
  display: flex
  align-items: center
  justify-content: space-between
  padding-top: 16px

  .toolbar__breadcrumbs
    text-decoration: none

    +mq-m
      grid-template-columns: 100%

    &__row
      display: flex
      align-items: center

      &__item
        position: relative
        margin-right: 8px

        &:after
          content: '/'
          position: absolute
          right: -8px
          top: 50%
          transform: translateY(-50%)

        &:last-child
          &:after
            display: none
