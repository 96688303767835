@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"





























































































































































































































































































































































































































































































$borderStyle: 1px solid transparentize($color-ink-lightest, 0.5)

.sas-table
  font-family: $font-family-primary
  width: 100%
  border-collapse: separate
  border-spacing: 0
  position: relative
  transition: all 200ms ease-in-out
  box-sizing: border-box

  td,
  th
    border-bottom: $borderStyle

  td
    box-sizing: border-box
    padding: $size-s $size-m
    font-size: 16px
    transition: all 200ms ease-in-out

  th
    padding: 12px $size-m
    text-align: left

  tr
    transition: all 200ms ease-in-out

    &:focus
      outline: 0

    &:hover
      background-color: transparentize($color-primary-lightest, 0.5)

    &:last-child
      td
        border-bottom: none

    &.--active
      background-color: transparentize($color-primary-lightest, 0.5)
      box-shadow: inset 0 0 0 2px $color-primary

  &__header
    &__field
      height: $size-s
      +flex-center-start

      &__icon
        margin-left: $size-xxs
        transition: all 200ms
        flex-shrink: 0

        &.--desc
          transform: scaleY(-1)

  tbody,
  tfoot
    tr:last-child
      th
        border-bottom: none

  thead
    text-align: left

    tr
      &:first-child
        th
          &:first-child
            border-top-left-radius: $size-xs

          &:last-child
            border-top-right-radius: $size-xs

    th
      background: transparentize($color-ink-lightest, 0.5)
      box-sizing: border-box
      color: $color-ink-light
      font-size: 11px
      font-weight: 400
      text-transform: uppercase
      vertical-align: middle
      letter-spacing: 1.3px

      &.--sortable
        cursor: pointer

      small
        font-weight: 600
        user-select: none

  tfoot
    tr:first-child
      td,
      th
        border-top: $borderStyle

  &.--vertical-lines
    td:not(:last-child),
    th:not(:last-child)
      border-right: $borderStyle
