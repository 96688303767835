@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"
















































































































$border: 1px solid $color-ink-lightest

.question-card
  margin-bottom: $size-s
  display: flex
  align-items: center
  justify-content: space-between
  cursor: pointer

  &__statement-section
    width: 75%
    display: flex
    align-items: center

    &__index-badge
      padding: $size-s
      border-right: $border

    &__statement-loader
      margin: 0 $size-s

    &__statement
      margin-left: $size-s
      margin-right: auto

  &__answer-section
    width: 25%
    display: flex
    align-items: center
    justify-content: center
    border-left: $border

    &.--loading
      padding: $size-s

    &__answer-badge
      padding: $size-s

      &__answered
        background: transparent

        ::v-deep .sas-badge__text
          font-size: $font-size-m
