@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"























































































































































































$font-weight-medium: 500
$font-weight-semi-bold: 600

.questions-table
  margin: $size-m 0

  &__index
    font-weight: $font-weight-semi-bold

  &__question
    font-weight: $font-weight-medium

  &__answer-badge
    background: transparent

    ::v-deep .sas-badge__text
      font-size: $font-size-m

  &__feedback
    font-weight: $font-weight-medium
