@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"



















































































































































































.placement-histogram
  display: flex
  flex-flow: column
  justify-content: space-between
  height: 100%

  &__explanation
    font-size: $font-size-m
    font-weight: $font-weight-semi-bold
    text-align: center
    padding: $size-xs $size-s
    margin-top: $size-s
    color: $color-ink-light

    ::v-deep span
      font-size: $font-size-m
      font-weight: $font-weight-semi-bold
      color: $color-primary

    &__loader
      margin: $size-s 0

  &__chart

    ::v-deep .vertical-bar-chart__bars
      width: 100%

    &__loading
      display: flex
      align-items: center
      justify-content: center
      margin: $size-m 0

  &__legend
    display: flex
    flex-flow: column
    align-items: flex-start
    margin-top: $size-l

    +mq-s--mf
      flex-flow: row
      justify-content: center

    .sas-chart-legend ~ .sas-chart-legend
      margin-top: $size-xs

      +mq-s--mf
        margin-top: 0
        margin-left: $size-m

    &__loader
      margin-bottom: $size-s

      +mq-m--mf
        margin-bottom: 0
        margin-right: $size-l
