@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






















































.drawer-navigator
  &__header
    padding: $size-s
      left: $size-l
    +flex-space-between

    &__controls
      +flex-center

      &__previous
        padding:
          right: $size-xs

      &__next
        border-right: 1px solid transparentize($color-ink-lightest, 0.5)
        padding:
          left: $size-xs
          right: $size-xs

      &__close
        padding:
          left: $size-xs
