@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"






























































.student-question-report

  &__section-title
    margin-top: $size-m

  &__section-subtitle
    margin-top: $size-xxs
    color: $color-ink-light

  &__cards-list
    margin: $size-m 0
