=proportional-video
  position: relative
  overflow: hidden
  padding-top: 56.25%

=proportional-video-iframe
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border: 0

=embedded-image
  width: auto !important
  max-width: 100% !important
  height: auto !important
  display: inline-block !important
  object:
    position: center center
    fit: contain

=embedded-paragraph
  line-height: 1.5 !important
  letter-spacing: 0.25px !important
  margin-block:
    start: 1em !important
    end: 1em !important
  word-break: break-word !important
