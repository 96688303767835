@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































































































































.header
  width: 100%

  &-body
    display: flex
    align-items: center
    justify-content: space-between
    padding: 32px 0px
    color: $color-ink

    &__title
      display: flex
      align-items: center

      .title-icon
        background: #5196D6
        border-radius: 12px
        padding: 12px
        box-sizing: border-box
        margin-right: 32px
        +flex-center

        +mq-s
          margin-right: 16px

        img
          width: 32px
          height: 32px

          +mq-s
            width: 24px
            height: 24px

      .title-text

        +mq-s
          font-size: 18px
