@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"













































.tab
  display: inline-block
  border-bottom: 4px solid none

  .btn--float
    margin-bottom: 4px
    text-decoration: none

  &--active
    opacity: 1

  &--light
    opacity: .75

    &.tab--active
      opacity: 1
      border-bottom: 4px solid $color-ink

      .btn--float
        color: $color-ink
