@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/mixins"

















































































































































































































.question-drawer
  &__header
    display: flex
    flex-flow: column
    align-items: flex-start

    +mq-m--mf
      flex-flow: row
      align-items: center

    .sas-badge

      +mq-m--mf
        margin-left: $size-s

    &__hit
      margin-left: $size-xs
      font-size: $font-size-s

      &.--success
        color: $color-success-dark

      &.--danger
        color: $color-danger-dark
